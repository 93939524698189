@import './../../app/styles/bootstrap.scss';

// w-testimonial-map
.ms-w-testimonial-map-marker-cluster {
    @extend .text-white;
    @extend .bg-secondary;
    @extend .p-4;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .d-flex;
    @extend .rounded-circle;
    height: 4rem !important;
    width: 4rem !important;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        height: 3rem;
        width: 3rem;
        border: 2px solid $primary;
        @extend .rounded-circle;
    }
}

.ms-w-testimonial-map-default-cluster-hide {
    height: 0px !important;
    width: 0px !important;
}

.ms-w-testimonial-map {
    width: 100%;
    height: 60vh;
    position: relative;
    @extend .rounded-5;
}

.ms-w-testimonial-map-overlay-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 500;
    top: 2rem;
    right: 2rem;
    background-color: $light;
    border: solid 3px $primary;
    @extend .p-4;
    max-height: 90%;
    max-width: 30%;
    z-index: $zindex-dropdown;
}

@include media-breakpoint-down(md) {
    .ms-w-testimonial-map-overlay-wrapper {
        max-width: 90%;
    }
}


//Stock Leaflet customizations
.leaflet-control {
    border: none !important;
}

.leaflet-control-zoom-in {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    display: flex;
    justify-content: center; 
    align-items: center;
    width: unset !important;
    height: unset !important;
    @extend .px-3;
    @extend .py-2;
    border: none !important;
}

.leaflet-control-zoom-out {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    display: flex;
    justify-content: center; 
    align-items: center;
    width: unset !important;
    height: unset !important;
    @extend .px-3;
    @extend .py-2;
    border: none !important;
}

.ms-w-testimonial-map-overlay-image {
    object-fit: contain;
    width: 100%;
    @extend .mt-md-4;
    @extend .mt-2;
    min-height: 0;
}

.ms-w-testimonial-map-text {
    /*@include media-breakpoint-up(lg) {
        position: absolute;
        //height: 100%;
        top: 20%;
        left: 2rem;
        z-index: 1000;
        width: unset;
        max-width: 30%;
    }
    */

    width: 100%;
    margin-top: 2rem;
}

/*
.ms-w-testimonial-map-container.has-text {
    @include media-breakpoint-up(lg) {
        margin-bottom: 20%;
    }
}
*/
// /w-testimonial-map